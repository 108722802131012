<template>
  <div
    :class="{ 'shrinkreport-content': drawer && !isMobile }"
    style="margin-top: 65px; padding: 5px"
  >

    <v-container fluid>
      <v-row>
        <v-col style="text-align: center;">
          <v-chip :class="{ 'selected': selectedCategory == VIDEO_CATEGORY.NEW }" @click="selectNew()">
            {{ $t('video.new') }}
          </v-chip>
          <v-chip v-for="videoCategory of videoCategories" v-bind:key="videoCategory.id"  :class="{ 'selected': selectedCategoryId == videoCategory.id }" @click="selectSpecific(videoCategory)">
            {{ videoCategory.name }}
          </v-chip>
          <v-chip :class="{ 'selected': selectedCategory == VIDEO_CATEGORY.ALL }" @click="selectAll()">
            {{ $t('video.all') }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row v-if="loadingVideos" class="videos-container">
        <v-col lg="3" md="4" sm="6" cols="12" v-for="i in [1,2,3,4,5,6,7,8]" v-bind:key="i">
          <v-skeleton-loader 
            class="mx-auto"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else class="videos-container" ref="videosContainer">
        <v-col lg="3" md="4" sm="6" cols="12" v-for="video of visibleVideos" v-bind:key="video.id" style="text-align:center">
          <router-link class="video-container" :to="'/videos/' + video.id + (selectedCategory == VIDEO_CATEGORY.SPECIFIC ? '?categoryId=' + selectedCategoryId : '' )">
            <v-hover v-slot="{ hover }" v-if="video.thumbnailUrl">
              <div class="video_wrapper">
                <img :src="video.thumbnailUrl" class="video-thumbnail" :class="{ 'hover': hover }" />
                <v-chip>
                  {{ video.duration }}
                </v-chip>
              </div>
            </v-hover>           
            <div v-else class="not-available">
              <div>
                <v-icon style="font-size:50px;">mdi-alert-circle-outline</v-icon>
                <div class="not-available-text">{{ $t('video.video_not_available') }}</div>
              </div>
            </div>
          </router-link>
          <div class="video-title">{{ video.title }}</div>
          <div class="video-subtitle">{{ video.creator }}</div>
          <div class="video-subtitle">{{ video.company }}</div>
        </v-col>
      </v-row>
      <v-row v-if="pagesCount > 1">
        <v-col>
          <v-pagination
            v-model="page"
            :length="pagesCount"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import authHeader from "@/services/auth-header";
import constants from "@/constants";
import { VIDEO_CATEGORY } from "@/enums";
import { getThumb } from '@/utils/video-thumbnail';

const axios = require('axios');
var API_URL = constants.API_URL + "videos/";

export default {
  name: "Videos",
  components: {
  },
  watch: {
    page() {
      this.$refs.videosContainer.scrollTo({top: 0, behavior: "smooth"});
    }
  },
  data: () => {
    return {
      videos: null,
      videoCategories: null,
      selectedCategory: VIDEO_CATEGORY.NEW,
      selectedCategoryId: null,
      loadingVideos: true,
      page: 1,
      perPage: 24,
      VIDEO_CATEGORY: VIDEO_CATEGORY
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    visibleVideos() {
      return this.videos.slice((this.page - 1)* this.perPage, this.page * this.perPage)
    },
    pagesCount() {
      if (!this.videos) {
        return 0;
      }
      return Math.ceil(this.videos.length / this.perPage);
    }
  },
  methods: {
    ...mapActions({
    }),
    async reloadVideos() {
      try {
        this.loadingVideos = true;

        this.page = 1;

        let res = await axios.get(API_URL + "?categoryEnum=" + this.selectedCategory + "&categoryId=" + this.selectedCategoryId + "&language=" + this.$i18n.locale, { headers: authHeader() });
        //console.log('getVideos', res.data)
        
        if (res && res.data) {
          this.videos = res.data.videos;

          for (let video of this.videos) {
            video.thumbnailUrl = await getThumb(video.url);
          }
        }
      }
      catch (err) {
        alert(err);
      }
      finally {
        this.loadingVideos = false;
      }
    },
    selectNew() {
      this.selectedCategoryId = null;
      this.selectedCategory = VIDEO_CATEGORY.NEW;
      this.reloadVideos();
    },
    selectAll() {
      this.selectedCategoryId = null;
      this.selectedCategory = VIDEO_CATEGORY.ALL;
      this.reloadVideos();
    },
    selectSpecific(videoCategory) {
      this.selectedCategoryId = videoCategory.id;
      this.selectedCategory = VIDEO_CATEGORY.SPECIFIC;
      this.reloadVideos();
    }
  },
  async mounted() {
    let res = await axios.get(API_URL + "getVideoCategories?language=" + this.$i18n.locale, { headers: authHeader() });
      //console.log('getVideoCategories', res.data)
      if (res && res.data) {
        this.videoCategories = res.data.videoCategories;
      }

    this.reloadVideos();
  },
};
</script>

<style scoped>
.v-chip {
  cursor: pointer;
}
.v-chip.selected {
  background: #009f4d;
  color: white;
}
  .v-chip + .v-chip {
    margin-left: 10px;
  }

.video-container {
  display: block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}
.video-container:hover {
  opacity: 0.8;
}

.video-container img {
  max-width: 100%;
}

.videos-container {
  max-height: unset;
  overflow: auto;
}

.not-available {
  text-align: center;
  background-color: #8080805e;
  width: calc(100% - 10px);
  margin-left: 5px;
  aspect-ratio: 4 / 3;
}
.not-available > div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.not-available-text {
  color: #5C5C5C;
  font-weight: bold;
  font-size: 1em;
  margin-top: 15px;
}

.video-title,
.video-subtitle {
  text-align: left; 
  margin-left: 5px;
  word-break: break-all;
}

.video-title {
  font-weight: bold;
}

.video-container .v-chip {
  position: absolute;
  right: 3px;
  bottom: 8px;
}

@media (min-width: 1000px) {
  .videos-container {
    max-height: calc(100vh - 200px);
  }
}

img.hover {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.3),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.2)!important;
}

.video_wrapper {
  position: relative;
  padding-bottom: 75%;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style> 